import React, { useState } from 'react'
import { Alert, Snackbar } from '@mui/material'

const NotficationSnackBar = ({ msg, color, open, closeSnackbar }) => {
    // const [open, setOpen] = useState(isOpen);


    return (
        <Snackbar
            open={open}
            autoHideDuration={4000}
            onClose={closeSnackbar}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
            <Alert
                onClose={closeSnackbar}
                severity={color}
                variant="filled"
                sx={{ width: '100%' }}
            >
                {msg}
            </Alert>
        </Snackbar>
    )
}

export default NotficationSnackBar
