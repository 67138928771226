import React from 'react'
import ShopSlider from '../../Components/Shop/ShopSlider'
import ShopSidebar from '../../Components/Shop/ShopSidebar'
import ShopProducts from '../../Components/Shop/ShopProducts'

const ShopPage = ({ products }) => {
    return (
        <div className='p-10 w-[95vw] flex flex-col items-center justify-center gap-10 pt-20'>
            <ShopSlider />
            <div className='flex items-start w-full justify-between max-md:w-[90vw]'>
                {window.innerWidth > 720 ? <ShopSidebar /> : null}
                <ShopProducts products={products} />
            </div>
        </div>
    )
}

export default ShopPage
