import React from 'react'
import LoginBanner from '../../Utills/Banner/LoginBanner'
import LoginForm from '../../Components/Login/LoginForm'
import ProfilePage from '../Profile/ProfilePage';

const LoginPage = () => {
    const user = JSON.parse(window.localStorage.getItem('template_user'));

    if (user) {
        return <ProfilePage />
    }

    return (
        <div className='p-10 w-[95vw] flex items-center justify-between gap-10 pt-20 max-md:p-0'>
            <LoginForm />
            {window.innerWidth > 720 ? <LoginBanner /> : null}
        </div>
    )
}

export default LoginPage
