import React, { useEffect, useState } from 'react'
import ProductAbout from '../../../Components/ProductDetail/ProductAbout'
import { useParams } from 'react-router-dom'

const ProductsDetail = ({ products }) => {
    const { id } = useParams();
    const [product, setProduct] = useState({});

    useEffect(() => {
        setProduct(products.find((e) => e._id === id));
    }, [id, products]);

    if (!product) {
        return <div></div>
    }

    return (
        <div className='p-10 w-[95vw] flex items-center justify-between gap-10 pt-20 max-md:px-0'>
            <ProductAbout product={product} />
        </div>
    )
}

export default ProductsDetail
