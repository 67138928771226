import React, { useState } from 'react'
import UserInfo from '../../Components/Profile/UserInfo'
import ProfileCategory from '../../Components/Profile/ProfileCategory'
import OwnedWeb from '../../Components/Profile/OwnedWeb';
import NameCardTable from '../../Components/Profile/NameCardList';
import AvailablePlan from '../../Components/Profile/AvailablePlan';
import SavedWeb from '../../Components/Profile/SavedWeb';

const ProfilePage = () => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const user = JSON.parse(window.localStorage.getItem('template_user'));

    return (
        <div className='p-10 w-[95vw] flex flex-col items-center justify-between gap-10 pt-20 max-md:p-0 max-md:py-20'>
            <UserInfo userInfo={user} />
            <div className='w-full flex flex-col gap-3'>
                <ProfileCategory selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} />
                <div className='w-full'>
                    <div className="divider"></div>
                </div>
            </div>
            {selectedIndex === 0 ? <OwnedWeb /> : null}
            {selectedIndex === 1 ? <NameCardTable /> : null}
            {selectedIndex === 2 ? <AvailablePlan /> : null}
            {selectedIndex === 3 ? <SavedWeb /> : null}
        </div>
    )
}

export default ProfilePage
