import React from 'react'
import crown from '../../Assets/crown.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import ProductCard from '../../Utills/ProductCard';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const SpecialProduct = ({ products, favorites, setFavorites }) => {
    return (
        <div className='flex w-[90vw] gap-5 max-md:flex-col'>
            <div className='bg-[#22BDFF] flex flex-col gap-5 rounded-xl w-[20%] h-[35vh] p-5 justify-center
            max-md:w-[90vw]'>
                <h2 className='text-[30px] font-semibold text-center text-white'>
                    Онцлох бүтээгдэхүүн
                </h2>
                <img src={crown} className='w-[8vw] h-[10vh] self-center max-md:w-[30vw]' alt="" />
            </div>
            {/* </SwiperSlide> */}
            <div className='w-[80%] max-md:w-[100%]'>
                <Swiper
                    spaceBetween={0}
                    className='max-md:w-full'
                    slidesPerView={window.innerWidth > 720 ? 4 : 1}
                    navigation={{ clickable: true }}
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                >
                    {products.map((e) => (
                        <SwiperSlide className=''>
                            <ProductCard favorites={favorites} setFavorites={setFavorites} {...e} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    )
}

export default SpecialProduct
