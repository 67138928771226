import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import gift from '../../Assets/gift.png'
import 'swiper/css';
import SecondaryButton from '../../Utills/Button/SecondaryButton';

const ShopSlider = () => {
    return (
        <div className='w-[90vw]'>
            <Swiper
                spaceBetween={0}
                slidesPerView={1}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
            >
                <SwiperSlide className=''>
                    <div className='flex items-center justify-center gap-32 rounded-2xl bg-[#22BDFF] w-[90vw] p-10 max-md:flex-col'>
                        <div className='flex flex-col items-start justify-center gap-10 '>
                            <h2 className='text-[45px] font-semibold opacity-none text-white w-[35vw] max-md:w-full max-md:text-[26px]'>
                                Нээлтийн урамшуулал
                                <span className='text-[#1d6188]'> 30-50% </span>
                                хямдарлаа
                            </h2>
                            <p className='text-white opacite opacity-100 w-[35vw] max-md:w-full'>Та захиалгаар өөрийн Вебсайт дээр нэмэлтээр хийлгэх боломжтой ирээдүйг харуулах мэт веб тэй болмоор байна уу </p>
                            <div className='flex gap-3'>
                                <SecondaryButton text={'Захиалга өгөх'} />
                            </div>
                        </div>
                        <img className='w-[20vw] h-[35vh] rounded-3xl max-md:hidden' src={gift} alt="" />
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    )
}

export default ShopSlider
