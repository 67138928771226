import React from 'react'

const SecondaryButton = ({ text, width, color, isLoad }) => {
    return (
        <> {!isLoad ?
            <button style={{ width: width ? width : '', color: color ? color : '#22BDFF' }}
                className='bg-[#fff] shadow-center rounded-lg py-1 px-4  font-semibold max-md:text-[13px]'>
                <p>{text}</p>
            </button> :
            <button style={{ width: width ? width : '', color: color ? color : '#22BDFF' }}
                className='bg-[#fff] shadow-center flex items-center justify-center rounded-lg py-1 px-4  font-semibold max-md:text-[13px]'>
                <div className="button-loader"></div>
            </button>
        }</>
    )
}

export default SecondaryButton
