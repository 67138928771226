import React from 'react'
import NewProductCard from '../../Utills/NewProductCard'
import SecondaryButton from '../../Utills/Button/SecondaryButton'

const NewProduct = ({ products }) => {
    return (
        <div className='flex flex-col w-[90vw] items-center gap-5'>
            <div className='grid grid-cols-5 justify-between gap-5 max-md:grid-cols-2'>
                {products.map((e) => (
                    <NewProductCard product={e} />
                ))}
            </div>
            <div className=''>
                <SecondaryButton width={window.innerWidth > 720 ? '' : '50vw'} text={'Бүгдийг үзэх'} />
            </div>
        </div>
    )
}

export default NewProduct
