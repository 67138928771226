import React from 'react';
import deal from '../../Assets/custom_order.png'
import SecondaryButton from '../../Utills/Button/SecondaryButton';
import { Link } from 'react-router-dom';

const CustomOrderSection = () => (
    <section className="flex w-[90vw] bg-[#22BDFF] text-white p-10 justify-around shadow-center rounded-xl max-md:flex-col max-md:items-center max-md:gap-5">
        <div className='flex flex-col justify-between items-start w-[25vw] max-md:w-full max-md:gap-4'>
            <h3 className='text-[30px] font-bold'>Ажлын захиалга өгөх</h3>
            <p>Та өөрийн хүссэн өнгө төрөх дизайны дагуу Аппликэшин, Вебсайтыг чанарын өндөр төвшинд захиалгаар манай баг хамт олноор хөгжүүлэх боломжтой</p>
            <div className='self-end'>
                <Link to={'/contact'}>
                    <SecondaryButton width={window.innerWidth > 720 ? '' : '40vw'} text={'Зочлох'} />
                </Link>
            </div>
        </div>
        <img src={deal} className='w-[200px] h-[200px]' alt="" />
    </section>
);

export default CustomOrderSection;
