import React, { useState } from 'react'
import PrimaryButton from '../../Utills/Button/PrimaryButton'
import SecondaryButton from '../../Utills/Button/SecondaryButton'
import { Link } from 'react-router-dom'
import axios from 'axios'
import NotficationSnackBar from '../../Utills/NotficationSnackBar'

const LoginForm = () => {
    const [snackbar, setSnackbar] = useState({ isOpen: false, msg: '', color: '' });
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = () => {
        const loginbody = {
            email: email,
            password: password,
        }

        axios.post('https://api.templateapi.xyz/user/login', loginbody)
            .then((e) => {
                window.localStorage.setItem('template_user', JSON.stringify(e.data.user))
                setSnackbar({
                    isOpen: true,
                    msg: 'Амжилттай нэвтэрлээ',
                    color: 'success',
                })
            }).then(() => {
                if (window.localStorage.getItem('template_user')) {
                    window.location.reload(false);
                }
            })
            .catch(() => {
                setSnackbar({
                    isOpen: true,
                    msg: 'Нууц үг эсвэл нэвтрэх нэр буруу байна',
                    color: 'error',
                });
            })
    };

    const closeSnackbar = () => {
        setSnackbar({ ...snackbar, isOpen: false });
    };

    return (
        <div className='flex flex-col w-[40vw] gap-5 justify-center items-center max-md:w-full max-md:h-screen'>
            <h2 className='text-[22px] font-semibold w-[50%] self-end text-[#22BDFF] max-md:w-[70%]'>Тавтай морил!</h2>
            <div className='w-[50%] self-center flex flex-col gap-5 justify-center items-center max-md:w-[70%]'>
                <div className='w-full'>
                    <p>И-мэйл:</p>
                    <input value={email} onChange={(e) => setEmail(e.target.value)} type="text" className='p-2' placeholder='И-мэйл' name="" id="" />
                </div>
                <div className='w-full'>
                    <p>Нууц үг:</p>
                    <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" className='p-2' placeholder='Нууц үг' name="" id="" />
                </div>
            </div>
            <div className='w-[50%] flex items-end justify-end max-md:w-[70%]'>
                <p className='w-[max-content] self-end border-b-2 border-[#333]'>Нууц үг мартсан</p>
            </div>
            <button onClick={handleSubmit} className='w-full'>
                <PrimaryButton text={'Нэвтрэх'} width={window.innerWidth > 720 ? '50%' : '70%'} />
            </button>
            <div className='flex gap-2 justify-center items-center w-[40%] max-md:w-[70%]'>
                <div className="divider w-[40%]"></div>
                <p>Эсвэл</p>
                <div className="divider w-[40%]"></div>
            </div>
            <Link to={'/signup'} className='flex self-center items-center justify-center w-full'>
                <SecondaryButton text={'Бүртгүүлэх'} width={window.innerWidth > 720 ? '50%' : '70%'} />
            </Link>
            {snackbar.isOpen && (
                <NotficationSnackBar
                    msg={snackbar.msg}
                    color={snackbar.color}
                    open={snackbar.isOpen}
                    closeSnackbar={closeSnackbar}
                    onClose={closeSnackbar}
                />
            )}
        </div>
    )
}

export default LoginForm
