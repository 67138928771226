import React from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei';
import PrimaryButton from './Button/PrimaryButton';
import SecondaryButton from './Button/SecondaryButton';
import model from '../Assets/model/robot_playground.glb'
import { Md360 } from "react-icons/md";
import { Link } from 'react-router-dom';

const RobotPlaygroundModel = () => {
    const gltf = useGLTF(model);
    return <primitive object={gltf.scene} scale={1} />;
};

const SwiperCard = () => {
    return (
        <div className='flex bg-[#22BDFF] w-[90vw] h-[60vh] rounded-3xl flex-col items-center justify-center max-md:items-start max-md:justify-start 
        max-md:w-[90vw] max-md:rounded-md max-md:flex-wrap'>
            <div className='bg-black opacity-40 w-[90vw] h-[60vh] absolute rounded-3xl 
            max-md:h-[30vh] max-md:rounded-md max-md:w-[90vw]'></div>
            <div className='flex w-full items-center justify-between max-md:flex-col max-md:h-full'>
                <div className='flex flex-col items-start justify-center gap-10 left-0 z-50 p-20 max-md:p-10 '>
                    <h2 className='text-[50px] font-semibold text-white w-[35vw] 
                max-md:w-[50vw] max-md:text-[20px]'>
                        Гайхамшиг мэт <span className='text-[#22BDFF]'>3D</span> Моделийг мэдэр
                    </h2>
                    <p className='text-white opacity-100 w-[40vw] max-md:text-[14px] max-md:hidden'>
                        Та захиалгаар өөрийн Вебсайт дээр нэмэлтээр хийлгэх боломжтой ирээдүйг харуулах мэт веб тэй болмоор байна уу
                    </p>
                    <div className='flex gap-3'>
                        <PrimaryButton text={'Дэлгэрэнгүй'} />
                        <Link to={'/contact'}>
                            <SecondaryButton text={'Захиалга өгөх'} />
                        </Link>
                    </div>
                </div>
                <div className='w-full h-full flex flex-col items-center justify-center'>
                    <Canvas>
                        <ambientLight intensity={0.5} />
                        <directionalLight position={[10, 10, 5]} />

                        <RobotPlaygroundModel />

                        <OrbitControls />
                    </Canvas>
                </div>
                <div className='absolute flex flex-col justify-center items-center font-bold text-[#d3d3d3] right-0 mr-[12vw] mt-[40vh]
                max-md:self-center max-md:bottom-0 max-md:right-10 max-md:mb-[2vh] max-md:text-[#333]'>
                    <Md360 size={50} />
                    <p>360 эргүүлж үзнэ үү</p>
                </div>
            </div>
        </div>
    );
};

export default SwiperCard;