import React, { useEffect, useState } from 'react'
import PrimaryButton from './Button/PrimaryButton'
import { Link } from 'react-router-dom'

const NewProductCard = ({ product }) => {
    const [img, setImage] = useState('');

    useEffect(() => {
        const firstImage = product.images?.[0]?.image;
        setImage(firstImage ? `https://api.templateapi.xyz/uploads/${firstImage}` : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRn0hr3Zv9xFM6-psSAqxrjsdlB-tfcYb_Juw&s');
    }, [product]);

    return (
        <div className='w-[17vw] shadow-center flex flex-col p-2 rounded-lg gap-3 py-5 max-md:w-[45vw]'>
            <p className='text-[#BC0000]'>Шинэ</p>
            <h2 className='text-[25px] font-semibold w-[15vw] max-md:w-[40vw] max-md:text-[17px]'>{product.title}</h2>
            <img className='w-[18vw] h-[8vw] shadow-center rounded-md max-md:w-[40vw] max-md:h-[15vh]'
                src={img} alt="" />
            <div className='flex justify-between items-center max-md:flex-col max-md:gap-3'>
                <p className='text-[13px] w-[12vw] text-[#838383] max-md:w-full'>Бизнесээ өрөгжүүлэхэд тань тусална</p>
                <div className='text-[13px]'>
                    <Link to={'/product/' + product._id}>
                        <PrimaryButton width={window.innerWidth > 720 ? '' : '30vw'} text={'Зочлох'} />
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default NewProductCard
