import React from 'react'
import ShopProductCard from '../../Utills/ShopProductCard'

const ShopProducts = ({ products }) => {
    return (
        <div className='w-[65vw] flex flex-col gap-5 max-md:w-[100%]'>
            <div className='bg-[#F8F8F8] flex items-center justify-between p-2 rounded-md max-md:w-[100%]'>
                <div className='flex items-center gap-3'>
                    <h2 className='text-xl font-semibold max-md:text-lg'>Нийт бүтээгдэхүүн</h2>
                    <p className='text-[#838383]'>(92 ширхэг)</p>
                </div>
                <select name="" id="">
                    <option value="">Бүгд</option>
                </select>
            </div>
            <div className='grid grid-cols-4 justify-items-center gap-10 max-md:grid-cols-2 max-md:gap-5'>
                {products.map((e) => (
                    <ShopProductCard product={e} />
                ))
                }
            </div>
        </div>
    )
}

export default ShopProducts
