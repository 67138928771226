import React, { useEffect, useState } from 'react';
import HeroSection from '../../Components/Home/Hero';
import SpecialProduct from '../../Components/Home/SpecialProduct';
import InstructionSection from '../../Components/Home/Instruction';
import NewProduct from '../../Components/Home/NewProduct';
import CustomOrderSection from '../../Components/Home/CustomOrder';
import HelpCenter from '../../Components/Home/HelpCenter';
import axios from 'axios';

const Home = ({ products }) => {
    const [favorites, setFavorites] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const user = JSON.parse(window.localStorage.getItem('template_user'));

    useEffect(() => {
        if (user) {
            axios
                .get('https://api.templateapi.xyz/favorite?user=' + user._id)
                .then((response) => {
                    setFavorites(response.data.data);
                })
                .catch((error) => {
                    console.error("Error fetching favorites:", error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false); // Ensure loading stops even if no user
        }
    }, [user]);

    if (isLoading) {
        return <div>Loading...</div>; // Render a loading state
    }

    return (
        <div className="p-10 w-[95vw] flex flex-col items-center justify-center gap-10 pt-20 max-md:pt-20 max-md:p-0">
            <HeroSection />
            <SpecialProduct favorites={favorites} setFavorites={setFavorites} products={products.filter((e) => e.isSpecial === true)} />
            <InstructionSection />
            <NewProduct products={products} />
            <CustomOrderSection />
            <HelpCenter />
            <div className="h-[10vh]"></div>
        </div>
    );
};

export default Home;
