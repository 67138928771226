import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { MdOutlineSearch, MdFavoriteBorder } from "react-icons/md";
import { HiOutlineUserCircle } from "react-icons/hi2";
import { FiShoppingCart } from "react-icons/fi";
import { CiMenuBurger } from "react-icons/ci";
import { IoClose } from "react-icons/io5";
import CustomSnackbar from '../../Utills/Snackbar';
import { useFrame } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';
import model from '../../Assets/model/robot_playground.glb';

const RobotPlaygroundModel = ({ scrollY }) => {
    const gltf = useGLTF(model);
    const ref = useRef();


    useFrame(() => {
        if (ref.current) {
            ref.current.position.y = scrollY * 2;
            ref.current.rotation.y = scrollY * Math.PI;
        }
    });

    return <primitive ref={ref} object={gltf.scene} scale={2} />;
};

const Navbar = () => {
    const [currentPage, setCurrentPage] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [scrollY, setScrollY] = useState(0);

    const user = JSON.parse(window.localStorage.getItem('template_user'));

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY || document.documentElement.scrollTop;
            const maxScroll = document.documentElement.scrollHeight - window.innerHeight;
            setScrollY(scrollTop / maxScroll);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (window.innerWidth > 720) {
            setIsOpen(true);
        }
    }, []);

    return (
        <div className='flex justify-center items-center w-screen fixed top-0 z-10 bg-white'>
            <div className='flex justify-between w-[90vw] items-center self-center py-2'>
                {window.innerWidth < 720 && isOpen ? (
                    <IoClose size={30} onClick={() => setIsOpen(false)} />
                ) : null}
                <div className='flex justify-center'>
                    {isOpen ? (
                        <div className='flex gap-4 navbar-mobile'>
                            <Link
                                to={'/'}
                                className={currentPage === 0 ? 'active-nav' : ''}
                                onClick={() => {
                                    setCurrentPage(0);
                                    window.innerWidth > 720 ?? setIsOpen(false);
                                }}
                            >
                                Нүүр
                            </Link>
                            <Link
                                to={'/shop'}
                                className={currentPage === 1 ? 'active-nav' : ''}
                                onClick={() => {
                                    setCurrentPage(1);
                                    window.innerWidth > 720 ?? setIsOpen(false);
                                }}
                            >
                                Дэлгүүр
                            </Link>
                            <Link
                                to={'/price'}
                                className={currentPage === 2 ? 'active-nav' : ''}
                                onClick={() => {
                                    setCurrentPage(2);
                                    window.innerWidth > 720 ?? setIsOpen(false);
                                }}
                            >
                                Үнэ
                            </Link>
                            <Link
                                to={'/contact'}
                                className={currentPage === 3 ? 'active-nav' : ''}
                                onClick={() => {
                                    setCurrentPage(3);
                                    window.innerWidth > 720 ?? setIsOpen(false);
                                }}
                            >
                                Холбоо барих
                            </Link>
                        </div>
                    ) : (
                        <CiMenuBurger size={30} onClick={() => setIsOpen(true)} />
                    )}

                    {/* <div className='w-[10vw] h-14 '>
                        <Canvas>
                            <ambientLight intensity={0.5} />
                            <directionalLight position={[10, 10, 5]} />
                            <RobotPlaygroundModel scrollY={scrollY} />
                            <OrbitControls enableZoom={false} />
                        </Canvas>
                    </div> */}
                </div>
                <div className='flex gap-4 bg-[#333] items-center justify-center px-5 py-2 rounded-3xl'>
                    <Link to={'/search'}>
                        <MdOutlineSearch size={30} color='#22BDFF' />
                    </Link>
                    {user ? (
                        <Link to={'/profile'}>
                            <MdFavoriteBorder size={30} color='#22BDFF' />
                        </Link>
                    ) : (
                        <CustomSnackbar color={'error'}>
                            <MdFavoriteBorder size={30} color='#22BDFF' />
                        </CustomSnackbar>
                    )}
                    <Link to={'/login'}>
                        <HiOutlineUserCircle size={30} color='#22BDFF' />
                    </Link>
                    {user ? (
                        <Link to={'/profile'}>
                            <FiShoppingCart size={30} color='#22BDFF' />
                        </Link>
                    ) : (
                        <CustomSnackbar color={'error'}>
                            <FiShoppingCart size={30} color='#22BDFF' />
                        </CustomSnackbar>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Navbar;
